import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Typography } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import CustomStyles from "./customStyles"
import GatsbyScriptEmbeds from "./gatsbyScriptEmbeds"

import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles"

import {
  getThemePalette,
  getThemeTypography,
  getThemeShape,
  getThemeOverrides,
} from "helpers"

const TopLayout = ({ children }) => {
  const themeConfig = useStaticQuery(
    graphql`
      query {
        sanityTheme(active: { eq: true }) {
          _rawPalette
          _rawShape
        }
        sanityFonts(active: { eq: true }) {
          _rawTypography
        }
        allSanityScriptEmbed {
          nodes {
            _id
            id
            active
            name
            scriptType
            loadingStrategy
            src
            inlineScript
            params {
              key
              keyType
              keyValue
            }
          }
        }
      }
    `
  )

  // If there is no theme file defined, then return nothing
  if (!themeConfig.sanityTheme) {
    return (
      <>
        <Typography variant="h3" color="error">
          There is no active theme defined.
        </Typography>
        {children}
      </>
    )
  } else {
    // if primaryMain exists then return it, otherwise return null
    // const primaryMain = themeConfig.sanityTheme?._rawPalette?.primary?.main ?? null

    const scripts = themeConfig.allSanityScriptEmbed?.nodes ?? []
    const palette = getThemePalette(themeConfig.sanityTheme?._rawPalette ?? {})
    const typography = getThemeTypography(
      // themeConfig.sanityTheme?._rawTypography ?? {}
      themeConfig.sanityFonts?._rawTypography ?? {}
    )
    const shape = getThemeShape(themeConfig.sanityTheme?._rawShape ?? {})
    const overrides = getThemeOverrides(
      themeConfig.sanityTheme?._rawOverrides ?? {}
    )

    let theme = createTheme({
      palette: palette,
      typography: typography,
      shape: shape,
      overrides: overrides.overrides,
      components: themeConfig.sanityTheme?._rawComponents ?? {},
    })

    // Make sure font sizes are responseive
    theme = responsiveFontSizes(theme)
    return (
      <>
        {/* Global Script Embeds */}
        <GatsbyScriptEmbeds scripts={scripts} />
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <CustomStyles />
          {children}
        </ThemeProvider>
      </>
    )
  }
}

export default TopLayout
